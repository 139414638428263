import { Heading, Img, Text } from "components";
import React from "react";

export default function Section4(){
  return (
    <div className="container flex justify-center items-center bg-purple">
      <div className="flex flex-row w-[90%] py-20 ">
        <div className="flex flex-col justify-center items-center sm:gap-5">
          <Img src="/images/home/wrench.png" alt="complete solution" style={{ width:"20%" }} />
          <Heading size="headinglg" className="uppercase text-center">Complete ASO Solution</Heading>
          <Text className="w-[70%] text-center" size="textmd">
          Complete ASO solutions from technology to execution, measurement, and more. 
          Gummicube's expert ASO team, paired with our proprietary DATACUBE and Splitcube technology, 
          will drive your app growth.
          </Text>
        </div>
      </div>
    </div>
  )

}