import React from "react";
import { Heading, Img } from "components";

export default function Section1 () {
  return (
    <div className="container">
      <div className="flex flex-row justify-center text-center pt-10">
        <div className="flex flex-col w-[90%]">
        <Heading size="headinglg" className="!text-gray-600">
        Trusted by thousands of the world's leading companies
        Gummicube is the #1 App Store Optimization Company
        </Heading>
        </div>
      </div>
      <div className="flex flex-row py-36 justify-center items-center ">
        <div className="flex flex-row sm:flex-col sm:gap-10 w-[90%] gap-8 justify-center items-center">
          <Img src="/images/home/bethesda-grayscale.png" className="w-[8rem]"  />
          <Img src="/images/home/gameloft-grayscale.png" className="w-[8rem]"  />
          <Img src="/images/home/grubhub-grayscale.png" className="w-[8rem]"  />
          <Img src="/images/home/linkedin-grayscale.png" className="w-[8rem]"  />
          <Img src="/images/home/mcafee-grayscale.png" className="w-[8rem]"  />
          <Img src="/images/home/microsoft-grayscale.png" className="w-[8rem]"  />
        </div>
      </div>
     
    </div>
  )
}