import React, { useEffect } from "react";
import Layout from "components/member/Layout";
import { Button, Text } from "components";
import Carousel from "./Carousel";
import { MdAttachMoney, MdPayment, MdOutlineShare } from "react-icons/md";
import GainSection from "./GainSection";
import CompanySection from "./CompanySection";
import MemberLevelSection from "./MemberLevelSection";
import PartnerSection from "./PartnerSection";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import { currenc, toUSD } from "lib/currencyFormatter";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AcredittedSection from "./AcredittedSection";

export default function Dashboard() {
  const userInfo = usePersonalInformation();
  const navigate = useNavigate();

  const initUserInfo = async () => {
    await userInfo.getData();
  };

  useEffect(() => {
    //if (userInfo.user._id === "") {
    initUserInfo();
    //}
  }, []);

  return (
    <Layout>
      <div className="container">
        <Carousel />
      </div>
      <div className="container flex justify-center  ">
        <div className="w-[90%]  border-0 rounded-lg bg-white p-3 z-10 mt-[-1.00rem]">
          <div className="flex flex-row w-full flex-grow">
            <div className="flex flex-col w-1/2 ">
              <Text className="!text-xl">Hello, </Text>
              <Text className="!text-2xl !font-semibold">
                {userInfo?.user?.fullName}
              </Text>
            </div>
            <div className="flex flex-col items-end w-full ">
              <Text className="text-3xl font-bold ">
                {toUSD(userInfo?.user?.myBalance)}
              </Text>
              <Text className="text-base">My balance</Text>
            </div>
            {/* <div className="flex flex-col col-auto">
                <Button className="btn-round" size="">
                  <MdAttachMoney className="w-[30px] h-[30px]" />
                </Button>
              </div> */}
          </div>
        </div>
      </div>
      <div className="container flex justify-center">
      <div className="flex flex-row sm:flex-col w-[90%] justify-center items-center gap-3">
        <div
          className=" mt-5  items-center flex flex-col  w-1/2 justify-center"
          onClick={() => {
            navigate("/dashboard/order");
          }}
        >
          <Button className="uppercase w-[10rem] h-[10rem] rounded-[50%]">
            $$ MAKE $$
          </Button>
        </div>
        <div className=" mt-5 flex w-1/2 flex-col justify-center items-center">
          <div className="flex flex-row flex-grow w-[90%] items-center justify-center sm:gap-3 lg:gap-20 ">
            <Link to="/dashboard/support">
              <div className="flex flex-col justify-center text-center gap-2">
                <Button
                  className="!bg-sky_blue_2 z-0 btn-round !h-[60px] !w-[60px] "
                  size=""
                >
                  <Button
                    className="btn-round !opacity-100 !z-30 bg-dark_blue-0 !w-[20px] !h-[20px]"
                    size=""
                  >
                    <MdAttachMoney />
                  </Button>
                </Button>
                <Text className="text-[0.75rem] !justify-center">Recharge</Text>
              </div>
            </Link>
            <Link to="/dashboard/profile/withdrawal">
              <div className="flex flex-col justify-center text-center gap-2">
                <Button
                  className="!bg-sky_blue_2 z-0 btn-round !h-[60px] !w-[60px] "
                  size=""
                >
                  <MdPayment fill="dark_blue" className="w-[20px] h-[20px]" />
                </Button>
                <Text className="text-[0.75rem] !justify-center">Withdraw</Text>
              </div>
            </Link>
            <Link to={`/dashboard/invitation`}>
              <div className="flex flex-col justify-center text-center gap-2">
                <Button
                  className="!bg-sky_blue_2 z-0 btn-round !h-[60px] !w-[60px] "
                  size=""
                >
                  <MdOutlineShare
                    fill="dark_blue"
                    className="w-[20px] h-[20px]"
                  />
                </Button>
                <Text className="text-[0.75rem] !justify-center">Invite</Text>
              </div>
            </Link>
          </div>
        </div>
      </div>          
      </div>
      
      <GainSection userInfo={userInfo} />
      <MemberLevelSection userInfo={userInfo} />
      <PartnerSection />
      <AcredittedSection />
    </Layout>
  );
}
