import React from "react";

const sizes = {
  subheading: "font-inter text-[1.50rem] font-normal not-italic lg:text-[1.50rem] md:text-[1.38rem]",
  body_text: "text-[1.00rem] font-normal",
  header_light:
    "font-opensanshebrewcondensed text-[1.88rem] font-normal not-italic lg:text-[1.88rem] md:text-[1.75rem] sm:text-[1.63rem]",
  placeholder: "text-[1.13rem] font-normal not-italic",
  textxs: "text-[0.88rem] font-normal not-italic",
  texts: "text-[0.94rem] font-normal not-italic",
  textmd: "text-[1.75rem] font-normal lg:text-[1.75rem] md:text-[1.63rem] sm:text-[1.50rem]",
  textxl: "text-[3.13rem] font-normal not-italic lg:text-[3.13rem] md:text-[2.88rem] sm:text-[2.50rem]",
};

const Text = ({ children, className = "", as, size = "body_text", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-black-900 font-opensanshebrew ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
