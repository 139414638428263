import { create } from 'zustand';
import { Api } from '../../../lib/api/axios';

const useBankStore = create((set, get) => ({
  data: [],
  totalData: 0,
  getData: async (searchTerm = '', page = 1, perPage = 10, sort = '', sortType = '', country='') => {
    try {
      const res = await Api.get(
        `/cms/bank?searchTerm=${searchTerm}&page=${page}&limit=${perPage}&sort=${sort}&sortType=${sortType}&country=${country}`
      );
      set(() => ({
        data: res.data?.docs,
        totalData: res.data?.totalDocs,
      }));
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  getAllBankCountry: async () =>{
    try{
      const res = await Api.get(`/cms/bank-country`);
      return res.data;
    }
    catch (error){
      return error?.response?.data;
    }
  },
  createData: async (payload) => {
    try {
      const { status } = await Api.post('/cms/bank', payload);
      await get().getData();
      return status;
    } catch (error) {
      return error.response?.data;
    }
  },
  updateData: async (id, payload, controller) => {
    try {
      const { status } = await Api.patch(`/cms/bank/${id}`, payload);
      await get().getData(controller?.search, controller?.page, controller?.perPage);
      return status;
    } catch (error) {
      return error.response?.data;
    }
  },
  deleteData: async (id, controller) => {
    try {
      const { status } = await Api.delete(`/cms/bank/${id}`);
      await get().getData(controller?.search, controller?.page, controller?.perPage);
      return status;
    } catch (error) {
      return error.response?.data;
    }
  },
}));

export default useBankStore;
