import React from "react";
import { Button } from "components";
import { MdHome, MdOutlinePlaylistAddCheck, MdOutlineSwipe, MdChat, MdOutlinePerson  } from "react-icons/md";
import {Link} from "react-router-dom";

export default function Footer(){
    return (
        <>
        {/* footer section */}
        <div className="fixed w-full h-auto z-10 left-0  bottom-0 bg-white opacity-80">
         <div className="flex flex-row w-full justify-center gap-9">
            <div className="flex flex-col"><Link to="/dashboard"><MdHome className="footer icon"/></Link></div>
            <div className="flex flex-col"><Link to="/dashboard/order-record"><MdOutlinePlaylistAddCheck className="footer icon"/></Link></div>
            <div className="flex flex-col"><Link to="/dashboard/order"><MdOutlineSwipe className="footer icon"/></Link></div>
            <div className="flex flex-col"><Link to="/dashboard/support"><MdChat  className="footer icon"/></Link></div>
            <div className="flex flex-col"><Link to="/dashboard/profile"><MdOutlinePerson  className="footer icon"/></Link></div>
         </div>
        </div>
      </>
    );
}