import React from 'react';
import { cva } from 'class-variance-authority';
import { clsxm } from '../../lib/utils';

const variants = cva('paragraph', {
  variants: {
    color: {
      primary: ['text-gray-300'],
      secondary: ['text-gray-300/60'],
      white: ['text-white'],
      green: ['text-c-green'],
      black: ['text-black'],
      red: ['text-red-500'],
    },
    size: {
      xs: ['text-xs'],
      small: ['text-sm'],
      medium: ['text-sm lg:text-base'],
      large: ['text-base lg:text-lg'],
    },
    padding: {
      none: '',
      normal: 'p-2',
    },
  },
  defaultVariants: {
    color: 'primary',
    size: 'small',
    padding: 'normal',
  },
});

const Paragraph = ({ className, size, padding, color, ...props })=> {
  return (
    <p
      className={clsxm(variants({ size, color, padding, className }))}
      {...props}
    />
  );
}

export {Paragraph};
