import useAuth from "../hooks/zustand/useAuth";
import usePersonalInformation from "../hooks/zustand/usePersonalInformation";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";

export default function AuthGuard({ children }) {
  const { auth } = useAuth((state) => ({ auth: state.auth }), shallow);
  const personalInformation = usePersonalInformation(
    (state) => ({ user: state.user, getData: state.getData }),
    shallow
  );

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  async function getPersonalInformation() {
    await personalInformation.getData();
    return;
  }
  // const attempt = useLocalStorage('withdrawalAttempt');
  const attempt = personalInformation.user.suspendedAttempts;
  useEffect(() => {
    let isMounted = true;
    if (auth?.accessToken && auth?.role?.replace(/["']/g, "") !== "user") {
      navigate("/admin/user/list");
    }
    if (!auth.accessToken) {
      navigate(`/`);
    }
    const maxSuspendAttempts =
      personalInformation?.user?.maxSuspendAttempts ??
      process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;
    if (
      personalInformation?.user?.status === "suspend" &&
      attempt >= maxSuspendAttempts
    ) {
      navigate(`/dashboard/withdraw`);
    }
    if (
      auth.accessToken &&
      personalInformation.user._id === "" &&
      auth.role?.replace(/["']/g, "") === "user"
    ) {
      getPersonalInformation().then(() => {
        if (!isMounted) return;
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.accessToken, personalInformation?.user._id, auth?.role]);

  return <div>{children}</div>;
}
