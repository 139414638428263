import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { HiOutlineX } from "react-icons/hi";
import ReactMarkdown from "react-markdown";

export default function ModalAlert({ open, setOpen, title, content, setParentPageReload=()=>{} }) {

  useEffect(()=>{
    if (open){
      const timeoutId = setTimeout(()=>{
        setOpen();
        setParentPageReload();
      }, 3000);
    }
  }, [open]);

  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => { }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-primary bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-message overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-5">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 max-w-full w-full lg:max-w-3xl">
                <div className="w-full relative">
                
                  <div className="sm:flex sm:items-start w-full">
                    <div className="w-full h-[10rem] flex flex-col">
                      <div className=" px-3.5 space-y-3 w-full h-full flex flex-col justify-center items-center bg-white relative">
                        <div className="absolute flex-row top-3 px-3.5 w-full flex justify-between items-center">
                          <div className="flex flex-col w-[50%]">
                            <img
                              className="w-20 md:w-28 sm:!w-[30%]"
                              alt="logo"
                              src={process.env.REACT_APP_LOGO_PATH}
                              //src="images/Produk-Kewangan-Black-Logo.png"
                            />
                          </div >
                          {/* <div  className="flex flex-col w-[40%] ">
                            <p className="text-sm md:text-lg font-semibold">Notification</p>
                          </div> */}
                          <div  className="flex flex-col w-[10%] justify-end items-end">
                            <button
                              className=" text-lg "
                              onClick={() => {
                                setOpen();
                                setParentPageReload();
                              }}
                            >
                              <HiOutlineX />
                            </button>
                          </div>
                          
                        </div>
                        <p className="text-2xl font-semibold">{title}</p>
                                
                        <ReactMarkdown
                          children={content}
                          className="prose prose-sm max-w-none prose-a:text-blue-500 prose-a:underline prose-li:marker:text-black"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
