import { memo, forwardRef, useId, useRef } from "react";

const Textarea = forwardRef((props, ref) => {
  const textareaRef = useRef(null);

  const handleLabelClick = () => {
    // Fokuskan textarea ketika label diklik
    textareaRef.current.focus();
  };

  return (
    <div className="relative mt-1 mb-4">
      <textarea
        ref={(textareaElement) => {
          // Simpan ref textarea ke textareaRef dan juga meneruskan ref ke prop
          textareaRef.current = textareaElement;
          if (typeof ref === "function") {
            ref(textareaElement);
          } else if (ref) {
            ref.current = textareaElement;
          }
        }}
        id={`floating${useId()}`}
        className="block border-gray-400 rounded-lg px-2.5 pb-2.5 pt-5 font-semibold w-full text-sm text-secondary bg-white appearance-none focus:outline-none focus:ring-0 focus:border-gray-900 peer border-low border-card-secondary/50 resize-none"
        placeholder=" "
        required={props.required}
        rows={props.row ?? 7}
        {...props}
      />
      <label
        htmlFor={`floating${useId()}`} // Gunakan id yang sesuai dengan textarea
        className="absolute text-sm text-secondary duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 font-semibold cursor-pointer"
        onClick={handleLabelClick} // Tambahkan event handler untuk klik label
      >
        {props.label}{" "}
        {props.required ? <span className="text-red-500">*</span> : null}
      </label>
      {props.error ? (
        <small className="text-red-500">{props.error}</small>
      ) : null}
    </div>
  );
});

export default memo(Textarea);
