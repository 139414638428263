import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/zustand/useAuth";
import useLogStore from "../../hooks/zustand/admin-log/useLogStore";
import { useNavigate, useLocation } from "react-router-dom";
import { handleLoginResponse } from "../../lib/api/handleApiResponse";
// components
import { FormProvider, RHFInput, RHFInputAddon } from "components/RHForm";
import ButtonLp from "components/ButtonLp";
import { enqueueSnackbar } from "notistack";
import ModalAccountLocked from "./Modal/Modal.account-locked";
import useLocalStorage from "hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import useHomeSlider from "hooks/zustand/useHomeSlider";
import RHLoginTypeDDL from "components/RHForm/RHLoginTypeDDL";
import RHDropDownList from "components/RHForm/RHDropDownList";
import { Text } from "components";
import { publicIpv4 } from "public-ip";

const schemaUsePhone = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone is required")
    .matches(/^\d+$/, "Number only!")
    .min(10, "Minimum 10 digit numbers")
    .max(13, "Maximum 13 digit numbers"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters"),
});

const schemaUseEmail = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters"),
});

const defaultValues = {
  phoneNumber: "",
  email: "",
  password: "",
};

const SignIn = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const logStore = useLogStore();
  const homeSliderStore = useHomeSlider();
  const [open, setOpen] = useState(false);
  const attempt = useLocalStorage("withdrawalAttempt");
  const location = useLocation();
  const { t } = useTranslation();
  const params = useParams();
  const [bgImg, setBgImg] = useState(process.env.REACT_APP_LOGIN_BG_IMG);
  const [bgColor, setBgColor] = useState(process.env.REACT_APP_LOGIN_BG_COLOR);
  const selectRef = useRef(null);
  const selectLanguageREf = useRef(null);
  const [selectedLoginType, setSelectedLoginType] = useState("phone");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [ipAddress, setIpAddress] = useState("");

  const initialGetBgImg = async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? "" !== "")
        setBgImg(resp.data.slider.bg_img);
    }
  };

  const initGetIp = async () => {
    const publicIp = await publicIpv4();
    if (publicIp) {
      setIpAddress(publicIp);
    }
  };
  useEffect(() => {
    if (ipAddress.trim() === "") {
      initGetIp();
      console.log(ipAddress);
    }
  }, []);

  useEffect(() => {
    if (location.state?.success === true) {
      enqueueSnackbar("Register successfully. You can login!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    navigate({ ...location, state: { success: false } }, { replace: true });

    //const currLanguage = params.language ?? process.env.REACT_APP_DEFAULT_LANGUAGE;
    i18n.changeLanguage(i18n.language);
    setSelectedLanguage(i18n.language);
    setBgColor(process.env.REACT_APP_LOGIN_BG_COLOR);
    initialGetBgImg();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const methods = useForm({
    resolver: yupResolver(
      selectedLoginType === "email" ? schemaUseEmail : schemaUsePhone
    ),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const cmdLoginType_onChange = (selectedOption) => {
    setSelectedLoginType(selectedOption);
  };

  const cmdLanguage_onChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    i18n.changeLanguage(selectedOption);
  };

  const onSubmit = async () => {
    const res = await auth.login(
      selectedLoginType === "phone" ? values.phoneNumber : values.email,
      values.password,
      selectedLoginType,
      ipAddress
    );
    const validated = handleLoginResponse(res, setError);
    if (
      validated &&
      res?.user?.role !== "admin" &&
      res?.user?.status !== "lock"
    ) {
      const memberId =
        selectedLoginType === "phone" ? values.phoneNumber : values.email;
      const maxSuspendAttempts =
        res?.user?.maxSuspendAttempts ??
        process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;
      if (
        res?.user?.status === "suspend" &&
        res?.user?.suspendedAttempts >= maxSuspendAttempts
      ) {
        await logStore.createData({
          adminId: "",
          memberId: memberId,
          log: `${memberId} login unsuccessful due to suspended`,
          payload: values,
          action: "login",
          module: "member",
          table: "users",
        });
        navigate(`/dashboard/withdraw`);
      } else {
        await logStore.createData({
          adminId: "",
          memberId: memberId,
          log: `${memberId} login successfully`,
          payload: values,
          action: "login",
          module: "member",
          table: "users",
        });
        navigate(`/dashboard`);
      }
    }
    if (validated && res.user?.role !== "" && res.user?.status === "lock") {
      setOpen(true);
      reset();
    }
    auth.setDefaultPassword("");
  };

  return (
    <>
      <div
        className="w-full h-screen relative"
        style={{
          //backgroundImage: `url('${bgImg}')`,
          opacity: 1,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: "0",
        }}
      >
        <div
          className="w-full h-screen relative"
          style={{
            opacity: 1,
            backgroundColor: bgColor,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: "1",
          }}
        ></div>
      </div>
      {/* <div className='flex absolute top-0 right-0 p-5 z-10'>
        <RHDropDownList
          ref={selectLanguageREf}
          defaultValue={selectedLanguage}
          optionLists={[
            { label: "EN", value: "en" },
            { label: "MY", value: "my" },
            { label: "CN", value: "cn" },
            { label: "JA", value: "ja" }
          ]}
          onChange={cmdLanguage_onChange}
        />
      </div> */}
      <div className="w-full h-screen absolute top-0 flex justify-center items-center flex-col">
        <div className="w-[80%]">
          <div className="flex justify-center">
            <img
              src={process.env.REACT_APP_LOGO_PATH}
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="!w-24  object-contain object-center"
            />
          </div>
          <div>
            <div className="">
              <div className="flex flex-col items-center">
                {/* <h1 className='text-4xl font-bold'>
                {t("home.signin.login")}
              </h1> */}
                <Text className="p-10">
                  The Poor and the middle class work for money, and the rich let
                  money work for them
                </Text>
              </div>
              {auth.defaultPassword !== "" ? (
                <div className="w-full bg-c-green/50 p-3 mb-5 rounded-lg">
                  <p className="text-sm font-semibold">
                    Here's your new password :{" "}
                    {auth.defaultPassword ? auth.defaultPassword : null}
                  </p>
                </div>
              ) : null}
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4 lg:gap-8 w-[50%] sm:w-full mx-auto">
                  {selectedLoginType === "phone" ? (
                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <label className="text-sm font-medium text-gray-900">
                          {t("home.signin.username")}
                        </label>
                      </div>
                      <div className="flex flex-row w-full">
                        <RHFInputAddon
                          name="phoneNumber"
                          addon={process.env.REACT_APP_COUNTRYCODE}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <label className="text-sm font-medium text-gray-900">
                          {t("home.signin.username")}
                        </label>
                      </div>
                      <div className="flex flex-row">
                        <div className="w-3/4">
                          <RHFInput name="email" placeHolder="Email" />
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-sm font-medium">
                        {t("home.signin.password")}
                      </label>
                    </div>
                    <RHFInput name="password" type="password" />
                  </div>

                  <ButtonLp
                    type="submit"
                    className="w-[50%] mx-auto mt-3"
                    loading={isSubmitting}
                  >
                    {t("home.signin.loginNow")}
                  </ButtonLp>
                </div>
              </FormProvider>
              <div>
                <h1 className="text-sm lg:text-md font-semibold text-center mt-5">
                  {t("home.signin.newhere")}
                  <Link to={`/sign-up`} className="font-bold underline">
                    {t("home.signin.createanaccount")}
                  </Link>
                </h1>
              </div>
              <div className="flex justify-center pt-3">
                <Link to="/">
                  <Text className="!text-sm lg:!text-md !font-semibold hover:!text-white">
                    <u>Home</u>
                  </Text>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalAccountLocked open={open} close={() => setOpen(false)} />
    </>
  );
};

export default SignIn;
