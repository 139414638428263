export default function toRinggit(amount = 0) {
  let formattedAmount = amount.toLocaleString("en-MY", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (amount < 0 && formattedAmount.includes(".")) {
    const parts = formattedAmount.split(".");
    const integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    formattedAmount = integerPart + "." + parts[1];
  }

  return "RM" + formattedAmount;
}

export function toUSD(amount = 0) {
  if (Math.abs(amount) < 0.01 ) {
    amount = 0;
  }

  let formattedAmount = amount.toLocaleString("en-MY", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (amount < 0 && formattedAmount.includes(".")) {
    const parts = formattedAmount.split(".");
    const integerPart = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    formattedAmount = integerPart + "." + parts[1];
  }

  return "$" + formattedAmount;
}

export function currency(amount = 0) {
  let formattedAmount = amount.toLocaleString("en-MY", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedAmount;
}

export function toFixedWithZeroCheck(number, decimalPlaces) {
  return Math.abs(number) < Math.pow(10, -decimalPlaces)
    ? (0).toFixed(decimalPlaces)
    : number.toFixed(decimalPlaces);
}
