import { Heading, Img, Text } from "components";
import React from "react";

export default function Section5(){
  return (
    <div className="container flex justify-center">
      <div className="flex flex-col gap-10 w-[90%] justify-center py-20" >
        <div className=" flex flex-row sm:flex-col items-center gap-10">
          <div className="flex flex-col justify-center items-center w-1/3 sm:w-full"> 
            <Img src="/images/home/keyword-optimization-150.png" alt="Search Optimization" style={{width:"30%"}}/>
            <Text size="textmd" className="!font-semibold">Search Optimization</Text>
            <Text className="text-center !text-lg" >Optimize your metadata to increase organic impressions and drive more organic 
              downloads from the App Store and Google Play. Let us optimize your app store presence.
              </Text>
          </div>
          <div className="flex flex-col justify-center items-center w-1/3 sm:w-full"> 
            <Img src="/images/home/search-optimization.png" alt="Search Optimization" style={{width:"30%"}}/>
            <Text size="textmd" className="!font-semibold">Conversion Optimization</Text>
            <Text className="text-center !text-lg" >Optimize your creatives for better conversion. 
              Drive more organic downloads, better rankings and lower user acquisition costs for
               every channel. Let us help you boost conversion and revenue.
              </Text>
          </div>
          <div className="flex flex-col justify-center items-center w-1/3 sm:w-full"> 
            <Img src="/images/home/a-b-testing.png" alt="Search Optimization" style={{width:"30%"}}/>
            <Text size="textmd" className="!font-semibold">A/B Multivariate Testing</Text>
            <Text className="text-center !text-lg" >Drive ROI with A/B Experimentation, Multivariate, 
              Split and Funnel Testing. Let us help you stay on top of trends and deliver what users 
              want.
              </Text>
          </div>
        </div>
        <div className=" flex flex-row sm:flex-col items-center gap-10">
          <div className="flex flex-col justify-center items-center w-1/3 sm:w-full"> 
            <Img src="/images/home/focus-groups.png" alt="Focus Group" style={{width:"30%"}}/>
            <Text size="textmd" className="!font-semibold">Focus Groups & Surveys</Text>
            <Text className="text-center !text-lg" >Use App Focus Groups to test icons, screenshots, 
              titles and descriptions for optimal conversion. Find out what users are saying about your app.
              </Text>
          </div>
          <div className="flex flex-col justify-center items-center w-1/3 sm:w-full"> 
            <Img src="/images/home/paid-search.png" alt="Paid search" style={{width:"30%"}}/>
            <Text size="textmd" className="!font-semibold">Paid Search</Text>
            <Text className="text-center !text-lg" >Apple Search Ads and Google Ads can help an app 
              index faster for keywords and perform better when managed side by side with ASO. 
              Let us help you align your organic and paid search traffic.
              </Text>
          </div>
          <div className="flex flex-col justify-center items-center w-1/3 sm:w-full"> 
            <Img src="/images/home/aso-technology.png" alt="Technology" style={{width:"30%"}}/>
            <Text size="textmd" className="!font-semibold">ASO Technology</Text>
            <Text className="text-center !text-lg" >ASO Technology from Gummicube is best-in-class. 
              DATACUBE and Splitcube can help you improve app growth and discoverability.
              </Text>
          </div>
        </div>
      </div>
    </div>
  )
}