import { useEffect, useState, useMemo } from "react";
import ModalDefault from "components/modal/modal.default";
import Input from "components/Input/Input";
import Textarea from "components/Input/input.text-area";
import Button from "components/Button/Button";
import InputSelect from "components/Input/Input.select";
import InputSelectSearch from "components/Input/Input.selectSearch";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { Paragraph, Spacing } from "components";
import useMemberListing from "../../../hooks/zustand/member/useMemberListing";
import useAdminAuth from "../../../hooks/zustand/admin/useAdminAuth";
import useLogStore from "../../../hooks/zustand/admin-log/useLogStore";
import useCommissionGroupStore from "hooks/zustand/commission/useCommissionGroupStore";
import { currency, toUSD } from "lib/currencyFormatter";
import InputNumber from "components/Input/Input.number";

const schemaUpdateMember = yup.object().shape({
  member: yup.object().shape({
    firstName: yup.string().required(),
    password: yup
      .string()
      .min(6, "Minimum 6 digit characters!")
      .max(32, "Maximum 32 digit characters!")
      .transform((value) => (value ? value : null))
      .nullable(),
    transactionPassword: yup
      .string()
      .min(6, "Minimum 6 digit characters!")
      .max(32, "Maximum 32 digit characters!")
      .transform((value) => (value ? value : null))
      .nullable(),
    phoneNumber: yup
      .string()
      .required("Phone is required")
      .matches(/^\d+$/, "Number only!")
      .min(9, "Minimum 9 digit numbers")
      .max(13, "Maximum 13 digit numbers"),
    status: yup.string().required(),
    creditScore: yup.number().required()
      .max(100, "Maximum score is 100")
      .min(0, "Minimum score is 100"),
  }),
  deposit: yup.object().shape({
    depositType: yup.string(),
    adjustmentType: yup.string(),
    amount: yup
      .number()
      .min(1, "Must be greater than 0")
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable(),
    remarks: yup.string(),
    updatedBy: yup.string(),
  }),
});

export default function UserInfo({
  open,
  close,
  userInfo,
  adminList,
  adminInfo,
  memberLevels,
  controller,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaUpdateMember),
  });

  const authLogin = useAdminAuth();
  const logStore = useLogStore();
  const commissionGroupStore = useCommissionGroupStore();
  const adminHandler = useMemo(() => {
    const admin = adminList.filter((val) => val._id === userInfo?.admin);
    return admin[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.admin]);
  const [handler, setHandler] = useState(adminHandler?._id);

  const memberList = useMemberListing();
  const [showMaxSuspendAttempts, setShowMaxSuspendAttempts] = useState(false);
  const [memberLevelList, setMemberLevelList] = useState([]);
  const [commissionGroupList, setCommissionGroupList] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [depositValue, setDepositValue] = useState();

  const getCommissionGroupList = async () => {
    const commissionGroups = await commissionGroupStore.getData();
    let objCommissionGroup = [];
    if (commissionGroups.totalDocs > 0) {
      commissionGroups.docs.map((item) => {
        return objCommissionGroup.push({
          _id: item._id,
          label: item.groupName,
        });
      });
      setCommissionGroupList(objCommissionGroup);
    }
  };

  const getAvailableBalance = async () => {
      const res = await memberList.getAvailableBalance(userInfo?._id);
      setAvailableBalance( res?.availableBalance);
  }

  useEffect(() => {

    if(userInfo?._id){
      getAvailableBalance();
    }
    

    if (userInfo?.status !== "suspend") setShowMaxSuspendAttempts(false);
    else setShowMaxSuspendAttempts(true);
    setValue("member._id", userInfo?._id);
    setValue("member.phoneNumber", userInfo?.phoneNumber);
    setValue("member.firstName", userInfo?.firstName);
    setValue("member.status", userInfo?.status);
    setValue("member.password", userInfo?.displayPassword);
    setValue(
      "member.transactionPassword",
      userInfo?.transactionDisplayPassword
    );
    setValue("member.admin", userInfo?.admin?._id);
    setValue("member.wallet", currency(userInfo?.wallet));
    setValue("member.frozenAmount", currency(userInfo?.frozenAmount));
    setValue("member.todayOrderCreated", userInfo?.todayOrderCreated ?? 0);
    setValue("member.upliner", userInfo?.upliner?.firstName);
    setValue("member.admin", userInfo?.admin);
    setValue("member.level", userInfo?.level);
    setValue(
      "member.maxSuspendAttempts",
      userInfo?.maxSuspendAttempts ??
        process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS
    );

    setValue("deposit.updatedBy", authLogin.admin?.adminId);
    setValue("member.creditScore", userInfo?.creditScore ?? 0);
    setValue("member.noTaskCompleted", userInfo?.noTaskCompleted ?? 0);
    setValue("member.withdrawMinAmt", userInfo?.withdrawMinAmt ?? 0);
    setValue("member.referralCode", userInfo?.referralCode ?? "");

    if (memberLevels) {
      const memberLevelFilter = memberLevels.map((item) => ({
        _id: item.level,
        label: item.name,
      }));
      setMemberLevelList(memberLevelFilter);
    }

    getCommissionGroupList();

    return () => {
      setDepositValue();
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, userInfo]);

  async function onSubmit(data) {
    try {
      if (data.member && data.member.password === null) {
        delete data.member.password;
      }
      data.member.frozenAmount = data.member.frozenAmount.replace(/,/g, "");
      const res = await memberList.updateData(data, controller);

      if (res?.status === 200) {
        await logStore.createData({
          adminId: authLogin.admin?.adminId,
          memberId: data?.member?.phoneNumber,
          member: data?.member?._id,
          log: `${authLogin.admin?.adminId} updated member ${data.member.phoneNumber} [${data.member.firstName}]'s profile successfully`,
          payload: data,
          action: "update",
          module: "member",
          table: "users",
        });
        enqueueSnackbar("Succes. Your data updated", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        close();
        reset();
      } else {
        enqueueSnackbar(
          `An error occured. ${res?.response?.data?.error?.message || null}`,
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ModalDefault
      open={open}
      setOpen={close}
      title={`Profile member - ${userInfo.accountNo}`}
      size="large"
    >
      <Spacing />
      <form onSubmit={handleSubmit(onSubmit, (e) => console.log(e))}>
        <div className="grid grid-cols-1">
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-3 mb-2">
            <div className="col-span-full lg:col-span-3">
              <Paragraph
                color="black"
                size="large"
                padding="none"
                className="font-semibold underline underline-offset-2"
              >
                Member Info
              </Paragraph>
            </div>
            <Input
              label="Mobile No"
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder={`Do not put "0" in front`}
              {...register("member.phoneNumber")}
              error={errors?.member?.phoneNumber?.message}
            />
            <Input
              label="Name As Per IC"
              type="text"
              error={errors?.member?.firstName?.message}
              {...register("member.firstName")}
            />
            <Input
              label="Date Joined"
              type="text"
              value={new Date(userInfo?.date)
                .toLocaleDateString()
                .replaceAll("/", "-")}
              readOnly
            />
            <InputSelect
              label="Status"
              required
              list={[
                { label: "Active" },
                { label: "Blank" },
                { label: "Suspend" },
                { label: "Lock" },
                { label: "Online" },
              ]}
              selected={userInfo?.status ?? "Blank"}
              {...register("member.status")}
              onChange={(e) => {
                if (e.target.value === "suspend") {
                  setShowMaxSuspendAttempts(true);
                } else {
                  setShowMaxSuspendAttempts(false);
                }
              }}
            />
            <Input
              id="wallet"
              label="Wallet"
              type="text"
              disabled="disabled"
              error={errors?.member?.wallet?.message}
              {...register("member.wallet")}
            />
            <Input
              id="frozenAmount"
              label="Frozen Amount"
              type="text"
              error={errors?.member?.frozenAmount?.message}
              {...register("member.frozenAmount")}
            />
            <Input
              id="withdrawMinAmt"
              label="Withdrawal Minimum"
              type="text"
              isDigit="true"
              required
              error={errors?.member?.withdrawMinAmt?.message}
              {...register("member.withdrawMinAmt")}
            />
            <Input
              id="No Of Task Completed"
              label="Today Order Created"
              type="text"
              error={errors?.member?.noTaskCompleted?.message}
              {...register("member.noTaskCompleted")}
            />
            <Input
              id="upliner"
              label="Upliner"
              type="text"
              disabled="disabled"
              error={errors?.member?.upliner?.message}
              {...register("member.upliner")}
            />
            <InputSelect
              label="Member Type"
              required
              list={[{ label: "Real" }, { label: "Fake" }]}
              selected={userInfo?.memberType ?? "Real"}
              {...register("member.memberType")}
            />
            <InputSelect
              label="Member Level"
              required
              list={memberLevelList}
              selected={userInfo?.level}
              {...register("member.level")}
            />
            <Input
              label="Credit Score"
              type="text"
              isDigit="true"
              required
              {...register("member.creditScore")}
              error={errors?.member?.creditScore?.message}
            />
            <Input
              id="pass"
              label="Password"
              type="text"
              error={errors?.member?.password?.message}
              {...register("member.password")}
            />
            <Input
              id="withdrawPassword"
              label="Withdrawal Password"
              type="text"
              error={errors?.member?.transactionPassword?.message}
              {...register("member.transactionPassword")}
            />
            <InputSelect
              label="CommissionGroup"
              list={commissionGroupList}
              selected={userInfo.commissionGroupId}
              {...register("member.commissionGroupId")}
            />
            {showMaxSuspendAttempts === true && (
              <Input
                id="maxSuspendAttempts"
                label="Max Suspended Attempts"
                type="text"
                required
                {...register("member.maxSuspendAttempts")}
                error={errors?.member?.maxSuspendAttempts?.message}
              />
            )}
             { availableBalance < 0 && (
              <Input
                type="text"
                label="Top up needed"
                disabled
                value={availableBalance}
              />
            )}
            <InputSelectSearch
              label="Handler"
              required
              list={
                adminInfo?.role !== "broker"
                  ? adminList?.map((a, b) => {
                      return { value: a?._id, label: a?.adminId };
                    })
                  : [{ label: adminInfo?.adminId || "", value: adminInfo?._id }]
              }
              defaultValue={adminHandler?.adminId || ""}
              onChange={(val) => setValue("member.admin", val.value)}
              // onChange={(selectedOption) => {
              //   const value = selectedOption ? selectedOption.value : null;
              //   setHandler(value);
              //   console.log(value);
              // }}
              // {...register("member.admin")}
            />
            {/* <Input
              type="hidden"
              value={handler ?? adminHandler?._id}
              disabled="true"
              {...register("member.admin")}
            ></Input> */}
           
          </div>
          <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-3 mb-2 mt-3">
            <div className="col-span-full lg:col-span-3 w-full">
              <Paragraph
                color="black"
                size="large"
                padding="none"
                className="font-semibold underline underline-offset-2"
              >
                Deposit Adjustment
              </Paragraph>
            </div>
            <Input
              label="Adjustment Type"
              type="text"
              placeholder="Initial / 2nd Card"
              {...register("deposit.adjustmentType")}
              error={errors?.deposit?.adjustmentType?.message}
            />
            <InputSelect
              label="Add/Reduce"
              list={[
                { label: "Add", _id: "debit" },
                { label: "Reduce", _id: "credit" },
              ]}
              {...register("deposit.depositType")}
            />
            
           <Input 
            label="Amount"
            isDigit="true"
            {...register("deposit.amount")}
            error={errors?.deposit?.amount?.message}
           />
            <div className="col-span-full lg:col-span-2">
              <Textarea label="Remarks" {...register("deposit.remarks")} />
            </div>
            <Input type="hidden" {...register("deposit.updatedBy")} />
            <Input type="hidden" {...register("member.referralCode")} />
          </div>
        </div>
        <div className="py-5 flex justify-end gap-3">
          <Button
            type="button"
            className="w-max px-5 py-2 text-primary text-sm font-semibold"
            radius="full"
            backgroundColor="cancel"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="w-max px-5 py-2 text-white text-sm font-semibold"
            radius="full"
            disabled={isSubmitting}
          >
            {!!isSubmitting ? "Please wait.." : "Save"}
          </Button>
        </div>
      </form>
    </ModalDefault>
  );
}
