import React, { forwardRef } from 'react';
import Select from 'react-select';

const InputSelectSearch = forwardRef(
  ({ label, required, defaultValue, list, name, ...rest }, ref) => {
    const selectOptions = list.map((option)=> ({
      label : option.label,
      value : option.value
    }));
    return ( 
      <div className='relative mt-1 mb-2 z-20 border-[1px] border-gray-500 rounded-lg'>
      <Select
        id='small'
        className='block rounded-lg px-2.5 pb-2.5 pt-5 font-semibold w-full text-sm text-secondary bg-white focus:outline-none focus:ring-0 peer border-low border-card-secondary/50 appearance-none'
        defaultValue={selectOptions.find(option => option.label === defaultValue)}
        isSearchable="true"
        isClearable="true"
        options={selectOptions}
        ref={ref}
        {...rest}
        >
      </Select>
      <label
        htmlFor='floating_standard'
        className='absolute text-sm peer text-secondary duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 font-semibold !capitalize'
      >
        {label} {required ? <span className='text-red-500'>*</span> : null}
      </label>
    </div>
    );
  }
);

export default React.memo(InputSelectSearch);
