import { Text } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

export default function RHFInputAddon({ name, label, addon, required, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="flex flex-col gap-1 w-full">
                    {label && (
                        <div className='flex flex-row gap-2'>
                             <label className="mb-1 text-sm font-medium text-gray-900">{label}</label>
                             {required && <Text className='!text-red-700'>*</Text> }
                        </div>
                       
                    )}
                    <div className="flex">
                        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-100 border border-r-0 border-gray-800 rounded-l-md">
                            {addon}
                        </span>
                        <input
                            {...field}
                            className="rounded-none rounded-r-lg border text-gray-900 focus:ring-blue-500 focus:border-blue-500 flex-1 min-w-0 w-full text-sm border-gray-800 p-2.5"
                            autoComplete="off"
                            {...other}
                            {...required ? "required" : ""}
                        />
                    </div>
                    {error?.message && (
                        <p className="text-red-500 text-xs">{error?.message}</p>
                    )}
                </div>
            )}
        />
    );
}
