import { memo, forwardRef, useId, useRef } from "react";

const Input = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const handleLabelClick = () => {
    // Fokuskan input ketika label diklik
    inputRef.current.focus();
  };

  const handleKeyDown = (e) => {
    if (props.isDigit && props.isDigit === "true") {
      const allowedKeys = [
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "Delete",
        "Tab",
        ".",
        ",",
      ];
      if (!/^[0-9]$/.test(e.key) && !allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
  };


  return (
    <div className="relative mt-1 mb-2 z-10 w-full ">
      <input
        ref={(inputElement) => {
          // Simpan ref input ke inputRef dan juga meneruskan ref ke prop
          inputRef.current = inputElement;
          if (typeof ref === "function") {
            ref(inputElement);
          } else if (ref) {
            ref.current = inputElement;
          }
        }}
        type={props.type}
        id={`floating${useId()}`}
        className={` ${props.className} block !border-gray-500 !rounded-lg !px-2.5 !pb-2.5 !pt-5 font-semibold w-full text-sm text-secondary bg-white appearance-none focus:outline-0 focus:ring-0 focus:border-black-900 peer border-low border-card-secondary/50`}
        placeholder={props.placeholder || " "}
        required={props.required}
        value={props.value}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <label
        htmlFor={`floating${useId()}`} // Gunakan id yang sesuai dengan input
        className={`absolute text-black-900 text-sm text-secondary duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5  ${
          props.placeholder
            ? "scale-75 -translate-y-4"
            : "peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
        } font-semibold cursor-pointer`}
        onClick={handleLabelClick} // Tambahkan event handler untuk klik label
      >
        {props.label}{" "}
        {props.required ? <span className="text-red-500">*</span> : null}
      </label>

      {props.error ? (
        <small className="text-red-500">{props.error}</small>
      ) : null}
    </div>
  );
});

export default memo(Input);
