import Button from "components/Button/Button";
import {Text} from "./../../";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import  {useNavigate } from "react-router-dom";

export default function HeaderForm ({formTitle = "", className=""}) {
   
  const navigate = useNavigate ();
    
    return (
        <>
            <div className={`${className} w-full h-auto fixed z-50 top-0 left-0 bg-dark_purple `}>
                <div className="flex flex-row mr-0 ml-0   "> 
                    <div className="flex flex-col justify-center items-center w-[10%]" 
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <Button backgroundColor="transparent" className="ml-5">
                        <IoIosArrowBack fill="white" />
                      </Button>
                      
                    </div>
                    <div className="flex flex-col w-full p-3 justify-center items-center">
                       <Text className="!text-white uppercase text-[1.0rem]">{formTitle}</Text> 
                    </div>
                </div>
            </div>
        </>
    ) 
}