import { Img, Text } from "components";
import React from "react";

export default function Section3() {
  return (
    <div className="container flex justify-center">
        <div className="flex flex-row sm:flex-col sm:gap-5 w-[90%] py-20 justify-center items-center ">
          <div className="flex flex-col w-1/3 sm:w-full justify-center items-center text-center"> 
            <Img src="/images/home/80.png" alt="80%"></Img>
            <Text className="!font-semibold" size="textmd">Organic Downloads</Text>
            <Text size="textmd">More than 80% of mobile app downloads start inside the store</Text>
          </div>
          <div className="flex flex-col w-1/3  sm:w-full  justify-center items-center text-center"> 
            <Img src="/images/home/websearch-main.png" alt="80%"></Img>
            <Text className="!font-semibold" size="textmd">It's Not Web Search</Text>
            <Text size="textmd">App Store & Google Play algorithms aren’t like web search</Text>
          </div>
          <div className="flex flex-col w-1/3  sm:w-full  justify-center items-center text-center"> 
            <Img src="/images/home/impact-main.png" alt="80%"></Img>
            <Text className="!font-semibold" size="textmd">Overall Impact</Text>
            <Text size="textmd">Accurately measure ASO impacts across your entire marketing funnel</Text>
          </div>
        </div>
    </div>
  )
}