import { create } from "zustand";
import Cookies from "js-cookie";
import { Api } from "../../lib/api/axios";

function getInitialAuthState() {
  const accessToken = Cookies.get("accesstoken") || null;
  const id = Cookies.get("id") || null;
  const role = Cookies.get("role") || null;
  return {
    accessToken,
    id,
    role,
  };
}

const useAuth = create((set) => ({
  auth: getInitialAuthState(),
  defaultPassword: "",
  login: async (identifier, pass, method, ipAddress) => {
    let loginMethod =
      method === "phone" ? "/auth/login-with-phone" : "/auth/login-with-email";
    let payload = {
      [method === "phone" ? "phoneNumber" : "email"]: identifier,
      password: pass,
      ipAddress: ipAddress
    };
    const res = await Api.post(loginMethod, payload);
    if (
      res.data?.user?.id &&
      res.data?.token &&
      res.data?.user?.status !== "lock"
    ) {
      Cookies.set("accesstoken", JSON.stringify(res.data.token), {
        expires: 3,
      });
      Cookies.set("id", res.data.user.id, { expires: 3 });
      Cookies.set("role", JSON.stringify(res.data.user.role), { expires: 3 });
      set({
        auth: {
          accessToken: res.data.token,
          id: res.data.user.id,
          role: res.data.user.role,
        },
      });
    }
    return res.data;
  },
  logout: () => {
    Cookies.remove("accesstoken");
    Cookies.remove("id");
    Cookies.remove("role");
    set({ auth: { accessToken: null, name: null } });
  },
  register: async (payload) => {
    try {
      const res = await Api.post("/auth/register", payload);
      return res;
    } catch (error) {
      return error.response.data;
    }
  },
  checkCredentials: async (payload) => {
    try {
      const res = await Api.post("/auth/check-phone", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  resetPasswordByPhone: async (payload) => {
    try {
      const res = await Api.post("/auth/reset-password", payload);
      return res.data;
    } catch (error) {
      return error;
    }
  },
  setDefaultPassword: (val) => {
    set(() => ({ defaultPassword: val }));
  },
}));

export default useAuth;
