import React from 'react';
import { cva } from 'class-variance-authority';
import { clsxm } from '../../lib/utils';

const variants = cva(
  'button w-full duration-300 disabled:bg-primary disabled:text-white disabled:cursor-not-allowed',
  {
    variants: {
      backgroundColor: {
        primary: ['bg-primary', 'hover:bg-primary/90'],
        secondary: ['bg-secondary', 'hover:bg-secondary/90'],
        white: ['bg-white', 'hover:bg-white/90'],
        green: ['bg-green-600', 'hover:bg-green-800/90'],
        red: ['bg-red-600', 'hover:bg-red-800/90'],
        cancel: [
          'bg-white hover:hover:bg-gray-50 ring-1 ring-inset ring-gray-300',
        ],
        'data-table-action': ['bg-black bg-opacity-20'],
      },
      radius: {
        low: 'rounded-md',
        medium: 'rounded-xl',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      backgroundColor: 'primary',
      radius: 'low',
    },
  }
);

function Button({ className, radius, backgroundColor, ...props }) {
  return (
    <button
      className={clsxm(variants({ radius, backgroundColor, className }))}
      {...props}
    />
  );
}

export default React.memo(Button);
