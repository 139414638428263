import React from "react";
import { Img, Text } from "components";
import { Splide, SplideSlide } from "@splidejs/react-splide";


export default function AcredittedSection() {
  return (
    <div className="container flex justify-center py-5">
      <div className="w-[90%] flex flex-col">
        <div className="pb-5">
          <Text className="!font-semibold uppercase">Accreditted By </Text>
        </div>
        <div className="flex flex-row w-full items-center justify-center self-center">
          <Splide
            options={ {
              rewind: true,
              width: "100%",
              gap   : '2rem',
              arrows: false,
              pagination: false,
              focus:"center",
             
            } }
          >
            <SplideSlide className="!w-[15rem]">
              <Img src="/images/home/cma.svg" alt="Canadian Marketing Association" className="h-[8rem] w-[80%]" />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img src="/images/home/ica.jpg" alt="Institute of Canadian Agencies" className="h-[8rem] w-[80%]" />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img src="/images/home/mers.jpg" alt="Consumers Council of Canadian" className="h-[8rem] w-[80%]" />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img src="/images/home/oca.jpg" alt="Canadian Office Consumer Affair" className="h-[8rem] w-[80%]" />
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </div>
  )
}
