import { Heading, Text, Img, Button } from "components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header () {
  const [bgChanged, setBgChanged] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50)
    {
      setBgChanged(true);
    }
    else {
      setBgChanged(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className={`w-full h-auto fixed z-10 top-0 left-0 backdrop-filter-none flex justify-center items-center ${bgChanged ? ' bg-dark_purple_2 opacity-40' : ''} `}>
      <div className="w-[90%] flex flex-row">
      <div className="flex flex-col w-[30%]  justify-center items-center ">
        <Img src={process.env.REACT_APP_LOGO_PATH} className="w-[70%] sm:w-[100%] pt-5"  />
      </div>
      <div className="flex flex-col w-full justify-center items-end">
        <div className="flex flex-row">
          <Link to="/sign-in" >
            <Button size="md" shape="round" > <Text className="!text-white">Login</Text> </Button>
          </Link>
        </div>
      </div>
      </div>
    
    </div>
  )
}