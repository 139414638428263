import React, { forwardRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';

const options = [
  {
    value: 'email',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <HiOutlineMail /> 
      </div>
    ),
  },
  {
    value: 'phone',
    label: (
      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#193261', color: '#FFFFFF' }}>
        <HiOutlinePhone /> 
      </div>
    ),
  },
];

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#193261',
    color: '#FFFFFF',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#193261',
    borderRadius: '0.375rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#FFFFFF',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const RHLoginTypeDDL = forwardRef(({ defaultValue, onChange }, ref) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find(option => option.value === defaultValue);
      setSelectedOption(defaultOption || null);
    } else {
      setSelectedOption(options[0] || null);
    }
  }, [defaultValue]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    if (onChange) {
      onChange(selected ? selected.value : null);
    }
  };

  return (
    <div className='flex items-center justify-center'>
      <Select
        ref={ref}
        id="cmbLoginType"
        options={options}
        styles={customStyles}
        value={selectedOption}
        onChange={handleChange}
      />
    </div>
  );
});

export default RHLoginTypeDDL;