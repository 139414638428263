import React from "react";
import { Img, Text } from "components";
import { Splide, SplideSlide } from "@splidejs/react-splide";

export default function PartnerSection() {
  return (
    <div className="container flex justify-center py-5">
      <div className="w-[90%] flex flex-col">
        <div className="pb-5">
          <Text className="!font-semibold uppercase">Partners </Text>
        </div>
        {/* <div className="flex flex-row w-full items-center justify-center self-center">
          <Splide
            options={{
              rewind: true,
              width: "100%",
              gap: "2rem",
              arrows: false,
              pagination: false,
              focus: "center",
            }}
          >
            <SplideSlide className="!w-[15rem]">
              <Img
                src="/images/partner/AmazonLogo.png"
                className="h-[8rem] w-[80%]"
              />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img
                src="/images/partner/eBayLogo.png"
                className="h-[8rem] w-[80%]"
              />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img
                src="/images/partner/ETSYLogo.png"
                className="h-[8rem] w-[80%]"
              />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img
                src="/images/partner/ShopeeLogo.png"
                className="h-[8rem] w-[80%]"
              />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img
                src="/images/partner/TemuLogo.png"
                className="h-[9rem] w-[80%]"
              />
            </SplideSlide>
            <SplideSlide className="!w-[15rem]">
              <Img src="/images/partner/TMallLogo.png" className="h-[10rem]" />
            </SplideSlide>
          </Splide>
        </div> */}
        <div className="flex flex-row w-full items-center justify-center self-center gap-20 sm:gap-3">
          <Img src="/images/partner/ApplePlayStore.png" className="w-[15%] sm:w-[50%]" />
          <Img src="/images/partner/GooglePlay.png" className="w-[12%] sm:w-[40%]" />
        </div>
      </div>
    </div>
  );
}
