import { Img, Text } from "components";
import React from "react";

export default function Footer() {
  return (
    <div className="container bg-purple flex justify-center pt-5">
      <div className="w-[90%]">
        <div>
          <Text size="textxs" className="sm:!text-[0.6rem]">Acreditted By : </Text>
        </div>
        <div className="flex flex-row justify-center items-center py-3  gap-[5%]">
          <Img
            src="/images/home/cma.svg"
            alt="Canadian Marketing Association"
            className=" h-[8rem] sm:h-[4rem] w-[8rem] "
          />
          <Img
            src="/images/home/ica.jpg"
            alt="Institute of Canadian Agencies"
            style={{ width: "15%" }}
            className=" h-[8rem] sm:h-[4rem] w-[90%] sm:ml-[-2rem]"
          />
          <Img
            src="/images/home/mers.jpg"
            alt="Consumers Council of Canadian"
            style={{ width: "15%" }}
            className="h-[8rem] sm:h-[4rem] w-[80%]"
          />
          <Img
            src="/images/home/oca.jpg"
            alt="Canadian Office Consumer Affair"
            style={{ width: "15%" }}
            className=" h-[8rem] sm:h-[4rem] w-[80%]"
          />
        </div>
        <div className="flex flex-row justify-center items-center pt-5 sm:pt-0">
          <div className="flex flex-col justify-center items-center">
            <Img
              src={process.env.REACT_APP_LOGO_PATH}
              className="p-3 w-[30%] sm:w-[15%]"
            />
            <div className="flex flex-col">
              <Text className="sm:!text-[0.6rem]">
                © {new Date().getFullYear()} {process.env.REACT_APP_TITLE} . All
                Rights Reserved.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
