export const handleLoginResponse = (res, setError) => {
  if (res?.message === 'Invalid Phone number!') {
    setError('phoneNumber', { type: 'custom', message: res.message });
    return false;
  }
  if (res?.message === 'Invalid Password!') {
    setError('password', { type: 'custom', message: res.message });
    return false;
  }
  if (res?.message === 'Invalid Password!') {
    setError('transactionPassword', { type: 'custom', message: res.message });
    return false;
  }
  return true;
};

export const handleRegisterResponse = (
  res,
  onError,
  setError,
  navigate,
  language = 'en'
) => {
  if (res?.status === 201) {
    navigate(`/`, {
      state: {
        success: true,
      },
    });
  } else {
    console.log("onError", res?.response?.data?.message);
    onError(
      res?.response?.data?.error === 'Phone number already exists'
        ? 'This mobile number had been registered. Please contact your Broker.'
        : res?.response?.data?.error === 'Invitation code is not exists' 
        ? "Invitation code is not exists"
        : res?.response?.data?.message
    ); 
  }
  
};
