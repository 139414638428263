import { memo, forwardRef, useId, useRef, useState } from "react";

const InputNumber = forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const [error, setError] = useState();

  const handleLabelClick = () => {
    inputRef.current.focus();
  };

  const handleTextChanged = (e) => {
    let inputValue = e.target.value;
    if (/^[0-9.,]*$/.test(inputValue)) {
      inputValue = formatWithThousandSeparators(inputValue);
      if (props.valueChanged) {
        props.valueChanged(inputValue);
      }
    }
  };

  const handleBlur = (e) => {
    if (props.value && props.value.trim() != "") {
      const sanitizedValue = props.value.replace(/,/g, "");
      if (props.valueChanged) {
        props.valueChanged(sanitizedValue); // Ensure value is updated correctly
      }
    }
    if (
      props.minValue &&
      props.minValue.trim() !== "" &&
      !isNaN(props.minValue)
    ) {
      const inputValue = e.target.value ?? 0;
      if (e.target.value.trim() !== "" && (parseFloat(inputValue) < parseFloat(props.minValue))) {
        setError("Value must be more than " + props.minValue);
        props.setIsError(true);
        return;
      }
      else{
        setError(null);
        props.setIsError(false);
      }
    }
    if (
      props.maxValue &&
      props.maxValue.trim() !== "" &&
      !isNaN(props.maxValue)
    ) {
      const inputValue = e.target.value ?? 0;
      if (inputValue.trim()!=="" && (parseFloat(inputValue) > parseFloat(props.maxValue))) {
        setError("Value must be less than " + props.maxValue);
        props.setIsError(true);
        return false;
      }
      else{
        setError(null);
        props.setIsError(false);
      }
    }

    if (props.decimal && props.decimal.trim() !== "" && !isNaN(props.decimal)) {
      let value = e.target.value ?? 0;
      if (value.trim() !== '' && (!isNaN(value))) {
        value = parseFloat(value);
        value = value.toFixed(props.decimal);
        props.valueChanged(value);
      }
    }

    if (props.required){
      if (e.target.value.trim() === "") {
        setError(props.label ?? "This field" + " is required");
        props.setIsError(true);
        return false;
      }
      else{
        setError(null);
        props.setIsError(false);
      }
    }

  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      ".",
      ",",
    ];
    if (!/^[0-9]$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const formatWithThousandSeparators = (value) => {
    const parts = value.replace(/,/g, "").split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1] ?? null;
    if (decimalPart) {
      if (parts[1].length === 0) decimalPart ="0";
      else decimalPart = parts[1];
    }

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalPart === null ? `${formattedInteger}` : `${formattedInteger}.${decimalPart}`
  };

  return (
    <div className="relative mt-1 mb-2 z-10 w-full">
      <input
        ref={(inputElement) => {
          inputRef.current = inputElement;
          if (typeof ref === "function") {
            ref(inputElement);
          } else if (ref) {
            ref.current = inputElement;
          }
        }}
        type="text"
        id={`floating${useId()}`}
        className={`${props.className} block !border-gray-500 !rounded-lg !px-2.5 !pb-2.5 !pt-5 font-semibold w-full text-sm text-secondary bg-white appearance-none focus:outline-0 focus:ring-0 focus:border-black-900  peer border-low border-card-secondary/50"`}
        placeholder={props.placeholder}
        required={props.required}
        value={props.value}
        onChange={(e) => {
          handleTextChanged(e);
        }}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      <label
        htmlFor={`floating${useId()}`}
        className={`absolute text-black-900 text-sm text-secondary duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 ${
          props.placeholder
            ? "scale-75 -translate-y-4"
            : "peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
        } font-semibold cursor-pointer`}
        onClick={handleLabelClick}
      >
        {props.label}
        {props.required ? <span className="text-red-500">*</span> : null}
      </label>

      {props.error ? (
        <small className="text-red-500">{props.error}</small>
      ) : null}
       {error ? (
        <small className="text-red-500">{error}</small>
      ) : null}
    </div>
  );
});

export default memo(InputNumber);
