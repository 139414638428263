import React, { forwardRef } from 'react';

const InputSelect = forwardRef(
  ({ label, required, selected, list, className, ...rest }, ref) => {
    const defaultSelected = list[0]?.label;

    return (
      <div className={` ${className}  relative mt-1 mb-2`}>
        <select
          ref={ref}
          id='small'
          className={` ${className} block rounded-lg px-2.5 pb-2.5 pt-5 font-semibold w-full text-sm text-secondary bg-white focus:outline-none focus:ring-0 peer border-low border-card-secondary/50 appearance-none `}
          {...rest}
          defaultValue={selected ?? defaultSelected}
        >
          {list.map((a, b) => {
              return (
                <option key={b} value={a._id ?? a.label?.toLowerCase()} selected = { a._id === selected }>
                  {a.label}
                </option>
              );
          })}
        </select>
        <label
          htmlFor='floating_standard'
          className='absolute text-sm peer text-secondary duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 font-semibold !capitalize'
        >
          {label} {required ? <span className='text-red-500'>*</span> : null}
        </label>
      </div>
    );
  }
);

export { InputSelect }
