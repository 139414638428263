import Button from "components/Button/Button";
import React, { useEffect, useState } from "react";
import { MdOutlineSupportAgent } from "react-icons/md"; 
import { Link } from "react-router-dom";

export default function Header () {
    const [bgChanged, setBgChanged] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        if(window.scrollY > 50){
          setBgChanged(true);
        }
        else{
          setBgChanged(false);
        }
      }
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.addEventListener('scroll', handleScroll);
      }
    })
    
    return (
        <>
            <div className={`w-full h-auto fixed z-10 top-0 left-0 backdrop-filter-none ${bgChanged ? 'bg-white opacity-30':''}  `}>
                <div className="flex flex-row mr-0 ml-0  flex-wrap "> 
                    <div className="flex flex-col flex-1"></div>
                    <div className="flex flex-col w-auto max-w-[100%] p-3">
                      <Link to="/dashboard/support">
                        <MdOutlineSupportAgent className="w-[30px] h-[30px]" />
                      </Link> 
                    </div>
                </div>
            </div>
        </>
    ) 
}