import React from "react";

const sizes = {
  body_text: "font-inter text-[1.25rem] font-medium",
  menu: "text-[1.13rem] font-bold",
  header:
    "font-opensanshebrewcondensed text-[2.50rem] font-extrabold lg:text-[2.50rem] md:text-[2.38rem] sm:text-[2.25rem]",
  header_2:
    "font-opensanshebrewcondensed text-[1.88rem] font-bold lg:text-[1.88rem] md:text-[1.75rem] sm:text-[1.63rem]",
  small_text: "font-inter text-[1.00rem] font-medium",
  textlg: "text-[1.88rem] font-medium lg:text-[1.88rem] md:text-[1.75rem] sm:text-[1.63rem]",
  headingxs: "text-[1.50rem] font-bold lg:text-[1.50rem] md:text-[1.38rem]",
  headings: "text-[1.88rem] font-semibold lg:text-[1.88rem] md:text-[1.75rem] sm:text-[1.63rem]",
  headingmd: "text-[2.13rem] font-bold lg:text-[2.13rem] md:text-[2.00rem] sm:text-[1.88rem]",
  headinglg: "text-[2.56rem] font-extrabold lg:text-[2.56rem] md:text-[2.31rem] sm:text-[1.94rem]",
  headingxl: "text-[3.13rem] font-bold lg:text-[3.13rem] md:text-[2.88rem] sm:text-[2.50rem]",
  heading2xl: "text-[3.31rem] font-extrabold lg:text-[3.31rem] md:text-[2.81rem] sm:text-[2.44rem]",
  heading3xl: "text-[5.00rem] font-extrabold lg:text-[5.00rem] md:text-[3.00rem]",
  heading4xl: "text-[6.25rem] font-extrabold lg:text-[6.25rem] md:text-[3.00rem]",
};

const Heading = ({ children, className = "", size = "header_2", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-dark_blue-1 font-opensanshebrewcondensed ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
