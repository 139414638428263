import { Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import { AdminWithGuard } from "../middleware/route.guard";
import Loading from "components/Loading";

const UserList = lazy(()=> import ('../pages/AdminDashboard/User'));
const WithdrawalApproval = lazy(()=> import('../pages/AdminDashboard/WithdrawalApproval'));
const WithdrawalHistory = lazy(()=> import('../pages/AdminDashboard/WithdrawalHistory'));
const CommissionGroupList = lazy(()=> import ('../pages/AdminDashboard/CommissionGroup'));
const CommissionList = lazy(()=> import ('../pages/AdminDashboard/Commission'));
const InAppChat = lazy(() => import('../pages/AdminDashboard/InAppChat'));
const ProductList = lazy(() => import('../pages/AdminDashboard/Product'));
const AdminList = lazy(() => import('../pages/AdminDashboard/Admin'));
const HomeSlider = lazy(() => import('../pages/AdminDashboard/HomeSlider'));
const UserInjectionList = lazy(() => import('../pages/AdminDashboard/UserInjection'));
const UserInjectionLog = lazy(() => import('../pages/AdminDashboard/UserInjection/InjectionLog'));

const UserListGuard = AdminWithGuard(UserList, "memberListing", null);
const WithdrawalApprovalGuard = AdminWithGuard(WithdrawalApproval, "withdrawalApproval", null);
const WithdrawalHistoryGuard = AdminWithGuard(WithdrawalHistory, "withdrawalApproval", null);
const CommissionGroupListGuard = AdminWithGuard(CommissionGroupList, "setting", null );
const CommissionListGuard = AdminWithGuard(CommissionList, "setting", null );
const ProductListGuard = AdminWithGuard(ProductList, "setting", null);
const InAppChatGuard = AdminWithGuard(InAppChat, "communication", null);
const AdminListGuard = AdminWithGuard(AdminList, "adminListing", null);
const HomeSliderGuard = AdminWithGuard(HomeSlider, "sliderMaintenance", null);
const UserInjectionListGuard = AdminWithGuard(UserInjectionList, "memberInjection", null);
const UserInjectionLogGuard = AdminWithGuard(UserInjectionLog, "memberInjection", null);


export default [
  <Route path="/admin/user/list" element={<Suspense fallback={<Loading />}><UserListGuard /></Suspense>} />,
  <Route path="/admin/wallet/withdrawal-approval" element={<Suspense fallback={<Loading />}><WithdrawalApprovalGuard /></Suspense>} />,
  <Route path="/admin/wallet/withdrawal-history" element={<Suspense fallback={<Loading />}><WithdrawalHistoryGuard /></Suspense>} />,
  <Route path="/admin/setting/commission-group" element={<Suspense fallback={<Loading />} ><CommissionGroupListGuard /></Suspense>} />,
  <Route path="/admin/setting/commission/:groupId" element={<Suspense fallback={<Loading />} ><CommissionListGuard /></Suspense>} />,
  <Route path="/admin/communication/in-app-chat" element={<Suspense fallback={<Loading />}><InAppChatGuard /></Suspense>} />,
  <Route path="/admin/setting/product" element={<Suspense fallback={<Loading />}><ProductListGuard /></Suspense>} />,
  <Route path="/admin/setting/home-slider" element={<Suspense fallback={<Loading />}><HomeSliderGuard /></Suspense>} />,
  <Route path="/admin/misc/admin-list" element={<Suspense fallback={<Loading />}><AdminListGuard /> </Suspense>} />,
  <Route path="/admin/user/user-injection" element={<Suspense fallback={<Loading />}><UserInjectionListGuard /></Suspense>} />,
  <Route path="/admin/user/user-injection-log/:id" element={<Suspense fallback={<Loading />}><UserInjectionLogGuard /></Suspense>} />
]



