import ModalUserInfo from 'components/modal/modal.user-info';
import {Spacing, Paragraph} from "components"
import Button from 'components/Button/Button';

export default function ModalAccountLocked({ open, close }) {
  return (
    <ModalUserInfo open={open} setOpen={close} title='Locked'>
      <Spacing />
      <Paragraph size='medium' padding='none' color='black'>
        Your account has been locked. Please contact your broker
      </Paragraph>
      <div className='flex justify-end mt-5'>
        <Button
          type='button'
          className='w-max px-5 py-2 text-primary text-sm font-semibold'
          radius='full'
          backgroundColor='cancel'
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>
      </div>
      <Spacing />
    </ModalUserInfo>
  );
}
