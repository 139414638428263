import React from "react";
import { Route } from "react-router-dom";
import Routes from "components/TopLoadingBar";
import SignIn from "pages/Authenticate/SignIn";
import AdminSignIn from "pages/Authenticate/Admin/SignIn";
import NotAuthGuard from "middleware/not.auth.guard";
import SignUp from "pages/Authenticate/SignUp";
import userRoutes from "routes/user.route";
import adminRoutes from "routes/admin.route";
import PageNotFound from 'pages/PageNotFound';
import Home from "pages/Home";

function App() {
  return (
      <Routes>
         <Route exact path="/" element={
              <Home />
          } />
         <Route path="/sign-up" element={ <SignUp  /> } />
         <Route path="/sign-in" element={
            <NotAuthGuard>
               <SignIn />
            </NotAuthGuard>
         } />
         <Route path="/sign-in-admin" element={
            <NotAuthGuard>
               <AdminSignIn />
            </NotAuthGuard>
         } />
         {userRoutes}
         {adminRoutes}
         <Route path="*" element={< PageNotFound /> } />
      </Routes>
  );
}

export default App;
