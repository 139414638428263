import { create } from 'zustand';
import { Api } from '../../../lib/api/axios';
import Cookies from 'js-cookie';

function getInitialAuthState() {
  const accessToken = Cookies.get('accesstoken') || null;
  const role = Cookies.get('role') || null;
  return { accessToken, role };
}

function getInitialAdminInfo() {
  return {
    _id: '',
    adminId: null,
    name: null,
    email: null,
    phoneNumber: null,
    referralCode: null,
    allowExport: null,
    showMemberPassword: null,
    isSendbirdUser: null,
    sbAccessToken:"",
    country: [null],
    role: null,
    status: null,
  };
}

const useAdminAuth = create((set) => ({
  auth: getInitialAuthState(),
  admin: getInitialAdminInfo(),
  login: async (payload) => {
    try {
      const res = await Api.post('/auth/admin/login', payload);
      if (res.data?.token) {
        Cookies.set('accesstoken', JSON.stringify(res.data.token), {
          expires: 3,
        });
        Cookies.set('role', JSON.stringify(res.data?.admin?.role), {
          expires: 3,
        });
        set({
          auth: {
            accessToken: res.data.token,
            role: res.data?.admin?.role,
            adminId: res.data?.admin?.adminId,
            _id: res.data?.admnin?._id
          },
        });
      }
      return res.data;
    } catch (error) {
      return error;
    }
  },
  logout: () => {
    Cookies.remove('accesstoken');
    Cookies.remove('id');
    set({ auth: { accessToken: null }, admin: getInitialAdminInfo() });
  },
  getMe: async () => {
    try {
      const res = await Api.get('/auth/admin/get-me');
      set({ admin: res.data });
      return;
    } catch (error) {}
  },
}));

export default useAdminAuth;
