import React from "react";
import { Text, Img } from "components";
import UserInfo from "pages/AdminDashboard/User/UserInfo";

export default function MemberLevelSection({userInfo}) {
  return (
    <div className="container py-5">
      <div className="flex flex-col items-center justify-center">
        <div className="flex   w-[90%] py-5">
          <Text className="uppercase !text-[.88rem] !font-bold">
            Membership Level
          </Text>
        </div>
        <div className="flex flex-row w-[90%] gap-10">
          <div className="flex w-1/2  pb-3 ">
            <div className="flex flex-row border-0 rounded-lg bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800  w-full h-[6.5rem]">
              <Img
                className="p-3 w-auto"
                src="/images/dashboard/vip-badge.png"
              />
              <div className="flex flex-col w-[70%] ">
                <div className="pl-2 pt-5 ">
                  <Text className="text-[1.388rem] !text-white pb-1">
                    Normal
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 pb-1 sm:invisible ">
                    Required Investment : $0.00
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 sm:invisible ">
                    Commission 1% | 38 Orders
                  </Text>
                </div>
              </div>
              <div className="w-[25%] flex justify-end mr-3 sm:invisible">
                <Text className="pt-4 !text-white font-semibold right-0">
                  { userInfo?.user?.level === 0 ? 'Current Level' : 'Join >>'}
                </Text>
              </div>
            </div>
          </div>
          <div className="flex w-1/2 pb-3">
            <div className="flex flex-row border-0 rounded-lg bg-gradient-to-r from-yellow-600 via-yellow-700 to-yellow-800  w-full h-[6.5rem]">
              <Img
                className="p-3 w-auto"
                src="/images/dashboard/vip-badge.png"
              />
              <div className="flex flex-col w-[70%]">
                <div className="pl-2 pt-5 ">
                  <Text className="text-[1.388rem] !text-white pb-1">
                    VIP 1
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 pb-1 sm:invisible ">
                    Required Investment : $799.00
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 sm:invisible">
                    Commission 1.5% | 41 Orders
                  </Text>
                </div>
              </div>
              <div className="w-[25%] flex justify-end mr-3 sm:invisible">
                <Text className="pt-4 !text-white font-semibold right-0">
                { userInfo?.user?.level === 1 ? 'Current Level' : 'Join >>'}
                </Text>
              </div>
            </div>
          </div>
         
        </div>
        <div className="flex flex-row w-[90%] gap-10">
        <div className="flex w-1/2 pb-3">
            <div className="flex flex-row border-0 rounded-lg bg-gradient-to-r from-orange-600 via-orange-700 to-orange-800  w-full h-[6.5rem]">
              <Img
                className="p-3 w-auto"
                src="/images/dashboard/vip-badge.png"
              />
              <div className="flex flex-col w-[70%]">
                <div className="pl-2 pt-5 ">
                  <Text className="text-[1.388rem] !text-white pb-1">
                    VIP 2
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 pb-1 sm:invisible ">
                    Required Investment : $1799.00
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 sm:invisible ">
                    Commission 2% | 43 Orders
                  </Text>
                </div>
              </div>
              <div className="w-[25%] flex justify-end mr-3 sm:invisible">
                <Text className="pt-4 !text-white font-semibold right-0">
                { userInfo?.user?.level === 2 ? 'Current Level' : 'Join >>'}
                </Text>
              </div>
            </div>
          </div>
          <div className="flex w-1/2 pb-3">
            <div className="flex flex-row border-0 rounded-lg bg-gradient-to-r from-emerald-600 via-emerald-700 to-emerald-800  w-full h-[6.5rem]">
              <Img
                className="p-3 w-auto"
                src="/images/dashboard/vip-badge.png"
              />
              <div className="flex flex-col w-[70%]">
                <div className="pl-2 pt-5 ">
                  <Text className="text-[1.388rem] !text-white pb-1">
                    VIP 3
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 pb-1 sm:invisible ">
                    Required Investment : $3599.00
                  </Text>
                  <Text className="text-[0.78rem] !text-gray-500 sm:invisible ">
                    Commission 5% | 48 Orders
                  </Text>
                </div>
              </div>
              <div className="w-[25%] flex justify-end mr-3 sm:invisible">
                <Text className="pt-4 !text-white font-semibold right-0">
                { userInfo?.user?.level === 3 ? 'Current Level' : 'Join >>'}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
