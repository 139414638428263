import React from "react";
import { Heading, Img, Text } from "components";

export default function Section2 (){
  return (
    <div className="container bg-purple flex  justify-center items-center">
      <div className="flex flex-row sm:flex-col w-[90%] justify-center items-center pt-10 gap-20">
        <div className="flex flex-col w-1/2 sm:w-full ">
          <div className="flex justify-center items-center text-center">
            <Heading size="headinglg" className="uppercase !font-semibold">
              Accurate Mobile Data Is Key for App Store Optimization
            </Heading>
          </div>
          <div className="flex flex-col text-justify pt-3">
            <Text size="textmd">
                  App Store Optimization (ASO) boosts keyword rankings and improves 
                  conversion for your app in the App Store and Google Play. 
                  Implementing ASO correctly can increase your organic downloads and 
                  dramatically reduce user acquisition costs.
            </Text>
          </div>
          <div className="flex flex-col text-justify pt-3">
            <Text size="textmd">
            Gummicube’s industry-leading ASO technologies can help you understand 
            how the Apple App Store and Google Play Store operate better than any other mobile company. 
            Our solutions drive growth with powerful insights, so you can focus on what matters.
            </Text>
          </div>
        </div>
        <div className="flex flex-col w-1/2 sm:w-full">
          <Img src="/images/home/accurate-data.png" alt="Accurate data"  />
        </div>
      </div>
    </div>
  )
}